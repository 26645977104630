import { template as template_8249b48993a04b6c800ece2693210fe4 } from "@ember/template-compiler";
const FKText = template_8249b48993a04b6c800ece2693210fe4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
