import { template as template_612be86f7e04439b9b540ef3fc5ca713 } from "@ember/template-compiler";
const FKControlMenuContainer = template_612be86f7e04439b9b540ef3fc5ca713(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
